import {
  get, post, update, get2, upload, download
} from './helpers'


export function getFaqKategori() {
  return get('/master/faq-kategori')
}

export function getFaqAll() {
  return get('/master/faq-all')
}

export function getNegara($data) {
  return get('/master/negara', $data)
}

export function getProvinsi(data) {
  return get('/master/provinsi', data)
}

export function getKota(data) {
  return get('/master/kota', data)
}
export function getJenisInformasi(data) {
  return get('/master/jenis-informasi', data)
}
export function getSumberInformasi(data) {
  return get('/master/sumber-informasi', data)
}
export function getDugaanPelanggaran(data) {
  return get('/master/dugaan-pelanggaran', data)
}

export function getMajelisHakim(data) {
  return get('/master/majelis-hakim', data)
}

export function getDiterimaMelalui(data) {
  return get('/master/diterima-melalui', data)
}

export function getBadanPeradilanTingkat1(data) {
  return get('/master/badan-peradilan/tingkat/1', data)
}

export function getBadanPeradilanTingkat2(data) {
  return get('/master/badan-peradilan/tingkat/2', data)
}

export function getBadanPeradilanTingkat3(data) {
  return get('/master/badan-peradilan/tingkat/3', data)
}

export function getJenisKerjasama(data) {
  return get('/master/jenis-kerjasama', data)
}

export function getJenisPihakTerlibat(id) {
  return get(`/master/jenis-pihak-terlibat/${id}`)
}

export function getPokokLaporanByLaporanId(id) {
  return get(`/master/pokok-laporan/${id}`)
}

// export function getJenisDokumen(id) {
//   return get(`/master/jenis-dokumen/${id}`)
// }

export function getNamaPengusul(id) {
  return get(`/master/nama-pengusul`)
}

export function getRole() {
  return get('/master/role')
}

export function getTim(id) {
  return get(`/master/tim/${id}`)
}

export function activationUser(data) {
  return post('/pengguna/activation', data)
}

export function pilihTimUser(data) {
  return post('/tim-user', data)
}

export function pilihKomisioner(data) {
  return post('/tim-user-majelis', data)
}

export function pilihKetuaTimMajelis(data) {
  return update('/tim-user-majelis', data)
}

export function pilihAnggotaTimPanel(data) {
  return post('/tim-user-panel', data)
}

export function pilihAnggotaTimAhli(data) {
  return post('/tim-user-ahli', data)
}

export function pilihPutusanTerlapor(data) {
  return post('/putusan-terlapor', data)
}
export function deletePutusanTerlapor(data) {
  return post('/putusan-terlapor/delete', data)
}

export function deleteTimUser($data) {
  return post('/tim-user/delete', $data)
}

export function deleteTimUserMajelis($data) {
  return post('/tim-user-majelis/delete', $data)
}

export function deleteTimUserPanel($data) {
  return post('/tim-user-panel/delete', $data)
}

export function deleteTimUserAhli($data) {
  return post('/tim-user-ahli/delete', $data)
}

export function pilihLaporanTim(data) {
  return post('/laporan-tim', data)
}

export function getJenisKelamin() {
  return get('/master/jenis-kelamin')
}

export function getInstansi() {
  return get('/master/instansi')
}

export function getJenisKesimpulanTim() {
  return get('/master/jenis-kesimpulan-tim')
}

export function getKerjasamaById(id) {
  return get(`/master/kerjasama/${id}`)
}

export function getPutusanPlenoById(id) {
  return get(`/pleno/putusan/${id}`)
}

export function getKerjasamaById2($data) {
  return get(`/master/kerjasama`, $data)
}

export function getUnitUtama() {
  return get('/master/unit-utama')
}

export function getJenisKelengkapan() {
  return get('/master/jenis-kelengkapan')
}

// export function getDataDashboard($data) {
//   return get('/master/data-dashboard', $data)
// }

export function getStatusKerjasama() {
  return get('/master/status-kerjasama')
}

export function getJenisLaporan() {
  return get('/master/jenis-laporan')
}

export function getJenisPerkara() {
  return get('/master/jenis-perkara')
}

export function getLingkunganPeradilan() {
  return get('/master/lingkungan-peradilan')
}

export function getKantorPenghubung() {
  return get('/master/kantor-penghubung')
}

export function getJenisPeradilan() {
  return get('/master/jenis-peradilan')
}

export function getTenagaAhli() {
  return get('/master/tenaga-ahli')
}

export function getKomisioner() {
  return get('/master/komisioner')
}

export function getStruktural() {
  return get('/master/struktural')
}

export function getPenetapan(data) {
  return get('/master/penetapan', data)
}

export function getStatusLaporan($data) {
  return get('/master/status-laporan', $data)
}

export function getKlasifikasiPelapor() {
  return get('/master/klasifikasi-pelapor')
}

export function getKuasaPelapor() {
  return get('/master/kuasa-pelapor')
}

export function getTimAhli() {
  return get('/master/tim-ahli')
}

export function getTimPanel() {
  return get('/master/tim-panel')
}
export function getTimPleno() {
  return get('/master/tim-pleno')
}

export function getTimMajelis() {
  return get('/master/tim-majelis')
}

export function getJenisSurat() {
  return get('/master/jenis-surat')
}

export function getJenisDokumen($data) {
  return get('/master/jenis-dokumen', $data)
}

export function getJenisDokumenMkh($data) {
  return get('/jenis-dokumen-mkh', $data)
}

export function simpanInvestigasi($data) {
  return update('/investigasi-advokasi', $data)
}

export function getInvestigasiAdvokasiByLaporanId(id) {
  return get(`/investigasi-advokasi/${id}`)
}

export function getLaporanRegisterId(id) {
  return get(`/laporan-register/${id}`)
}

export function getMonitoringPersidanganById(id) {
  return get(`/monitoring-persidangan/${id}`)
}
export function getTplById(id) {
  return get(`/tpl-monitoring/${id}`)
}
export function getTplAnalisisById(id) {
  return get(`/tpl-analisis/${id}`)
}

export function getTplPemeriksaanPendahuluanById(id) {
  return get(`/tpl-pemeriksaan-pendahuluan/${id}`)
}
export function updateTplPemeriksaanPendahuluan($data) {
  return update(`/tpl-pemeriksaan-pendahuluan`, $data)
}
export function uploadFileLPP(file, onUploadProgress) {
  return upload(`/tpl-pemeriksaan-pendahuluan/uploadfile`, file, onUploadProgress)
}
export function downloadFileLPP($filename) {
  return download(`tpl-pemeriksaan-pendahuluan/download/${$filename}`)
}
export function uploadFileBapPelapor(file, onUploadProgress) {
  return upload(`/bap-pelapor/uploadfile`, file, onUploadProgress)
}
export function downloadFileBapPelapor($filename) {
  return download(`bap-pelapor/download/${$filename}`)
}
export function uploadFileBapKonfrontir(file, onUploadProgress) {
  return upload(`/bap-konfrontir/uploadfile`, file, onUploadProgress)
}
export function downloadFileBapKonfrontir($filename) {
  return download(`bap-konfrontir/download/${$filename}`)
}
export function uploadFileBapTerlapor(file, onUploadProgress) {
  return upload(`/bap-terlapor/uploadfile`, file, onUploadProgress)
}
export function downloadFileBapTerlapor($filename) {
  return download(`bap-terlapor/download/${$filename}`)
}

export function updateLPP($data) {
  return update(`/tpl-pemeriksaan-pendahuluan/lpp`, $data)
}
export function updateLHP($data) {
  return update(`/tpl-pemeriksaan-lanjutan/lhp`, $data)
}

export function getPlenoJadwalById(id) {
  return get(`/jadwal-pleno/${id}`)
}
export function updatePlenoJadwal($data) {
  return update(`/jadwal-pleno`, $data)
}
export function deletePlenoJadwal($data) {
  return post('/jadwal-pleno/delete', $data)
}
export function updatePlenoJadwalTindakan($data) {
  return update(`/jadwal-pleno/update-tindakan`, $data)
}
export function updateHasilSidangPleno($data) {
  return update(`/jadwal-pleno/hasil-sidang`, $data)
}
export function updateBeritaAcaraPleno($data) {
  return update(`/jadwal-pleno/berita-acara`, $data)
}
export function downloadFilePlenoJadwal($filename) {
  return download(`jadwal-pleno/download/${$filename}`)
}

export function tambahNomorRegisterJadwalPanel($data) {
  return post('/panel-jadwal/tambah-nomor-register', $data)
}
export function hapusNomorKerjasamaJadwalPanel($data) {
  return post('/panel-jadwal/hapus-nomor-register', $data)
}
export function getPanelJadwalById(id) {
  return get(`/panel-jadwal/${id}`)
}
export function updatePanelJadwal($data) {
  return update(`/panel-jadwal`, $data)
}
export function deletePanelJadwal($data) {
  return post('/panel-jadwal/delete', $data)
}
export function batalJadwalPanel($data) {
  return post('/panel-jadwal/batal', $data)
}
export function tambahDataPanelJadwal($data) {
  return post('/panel-jadwal', $data)
}
export function tambahDataPlenoJadwal($data) {
  return post('/jadwal-pleno', $data)
}
export function updateDataPanelJadwal($data) {
  return post('/update-panel-jadwal', $data)
}
export function updateDataPlenoJadwal($data) {
  return post('/update-pleno-jadwal', $data)
}
export function updatePanelJadwalTindakan($data) {
  return update(`/panel-jadwal/update-tindakan`, $data)
}
export function updateHasilSidangPanel($data) {
  return update(`/panel-jadwal/hasil-sidang`, $data)
}
export function updateBeritaAcaraPanel($data) {
  return update(`/panel-jadwal/berita-acara`, $data)
}
export function updateJadwalPanelPutusan($data) {
  return post('/panel-jadwal/update-putusan', $data)
}
export function updateJadwalPlenoPutusan($data) {
  return post('/jadwal-pleno/update-putusan', $data)
}
export function updatePenetapanPanel($data) {
  return update(`/panel-jadwal/penetapan`, $data)
}
export function updatePenetapanPleno($data) {
  return update(`/jadwal-pleno/penetapan`, $data)
}
export function uploadFilePanelJadwal(file, onUploadProgress) {
  return upload(`/panel-jadwal/uploadfile`, file, onUploadProgress)
}
export function uploadFilePlenoJadwal(file, onUploadProgress) {
  return upload(`/jadwal-pleno/uploadfile`, file, onUploadProgress)
}
export function downloadFilePanelJadwal($filename) {
  return download(`panel-jadwal/download/${$filename}`)
}

export function downloadTemplate($templateId, $laporanId) {
  return download(`laporan/download/${$templateId}/${$laporanId}/docx`)
}
export function downloadTemplatePdf($templateId, $laporanId) {
  return download(`laporan/download/${$templateId}/${$laporanId}/pdf`)
}

export function generatePenetapan($jadwalPanelId,noSurat) {
  return download(`panel-jadwal/generate-penetapan/${$jadwalPanelId}/${noSurat}`)
}
export function generatePenetapanPleno($jadwalPlenoId,noSurat) {
  return download(`jadwal-pleno/generate-penetapan/${$jadwalPlenoId}/${noSurat}`)
}

export function getStatusJadwalPemeriksaanById(id) {
  return get(`/tpl-jadwal-pemeriksaan/${id}`)
}
export function updateStatusJadwalPemeriksaan($data) {
  return update(`/tpl-jadwal-pemeriksaan/status`, $data)
}
export function getJadwalPemeriksaanById(id) {
  return get(`/tpl-jadwal-pemeriksaan/${id}`)
}
export function tambahDataJadwalPemeriksaan($data) {
  return post('/tpl-jadwal-pemeriksaan', $data)
}
export function deleteJadwalPemeriksaan($data) {
  return post('/tpl-jadwal-pemeriksaan/delete', $data)
}
export function updateJadwalPemeriksaan($data) {
  return update(`/tpl-jadwal-pemeriksaan`, $data)
}
// export function uploadFileLPP(file, onUploadProgress) {
//   return upload(`/tpl-pemeriksaan-pendahuluan/uploadfile`, file, onUploadProgress)
// }
// export function downloadFileLPP($filename) {
//   return download(`tpl-pemeriksaan-pendahuluan/download/${$filename}`)
// }
// export function updateLPP($data) {
//   return update(`/tpl-pemeriksaan-pendahuluan/lpp`, $data)
// }

export function getTplPemeriksaanLanjutanById(id) {
  return get(`/tpl-pemeriksaan-lanjutan/${id}`)
}
export function updateTplPemeriksaanLanjutan($data) {
  return update(`/tpl-pemeriksaan-lanjutan`, $data)
}
export function uploadFileLHP(file, onUploadProgress) {
  return upload(`/tpl-pemeriksaan-lanjutan/uploadfile`, file, onUploadProgress)
}
export function downloadFileLHP($filename) {
  return download(`tpl-pemeriksaan-lanjutan/download/${$filename}`)
}
// ==============Data master ==========
//================unit utama=================
export function tambahDataUnitUtama($data) {
  return post('/unit-utama', $data)
}
export function deleteUnitUtama($data) {
  return post('/unit-utama/delete', $data)
}
export function getUnitUtamaById(id) {
  return get(`/unit-utama/${id}`)
}
export function updateUnitUtama($data) {
  return update('/unit-utama', $data)
}
//======================================
//===============negara==================
export function tambahNegara($data) {
  return post('/negara', $data)
}
export function deleteNegara($data) {
  return post('/negara/delete', $data)
}
export function getNegaraById(id) {
  return get(`/negara/${id}`)
}
export function updateNegara($data) {
  return update('/negara', $data)
}
// =====================================
//===============provinsi==================
export function tambahProvinsi($data) {
  return post('/provinsi', $data)
}
export function deleteProvinsi($data) {
  return post('/provinsi/delete', $data)
}
export function getProvinsiById(id) {
  return get(`/provinsi/${id}`)
}
export function updateProvinsi($data) {
  return update('/provinsi', $data)
}
// =====================================
//===============kota==================
export function tambahKota($data) {
  return post('/kota', $data)
}
export function deleteKota($data) {
  return post('/kota/delete', $data)
}
export function getKotaById(id) {
  return get(`/kota/${id}`)
}
export function updateKota($data) {
  return update('/kota', $data)
}
// =====================================
//===============status laporan==================
export function tambahStatusLaporan($data) {
  return post('/status-laporan', $data)
}
export function deleteStatusLaporan($data) {
  return post('/status-laporan/delete', $data)
}
export function getStatusLaporanById(id) {
  return get(`/status-laporan/${id}`)
}
export function updateStatusLaporan($data) {
  return update('/status-laporan', $data)
}
// =====================================
//===============dugaan pelanggaran==================
export function tambahDugaanPelanggaran($data) {
  return post('/dugaan-pelanggaran', $data)
}
export function deleteDugaanPelanggaran($data) {
  return post('/dugaan-pelanggaran/delete', $data)
}
export function getDugaanPelanggaranById(id) {
  return get(`/dugaan-pelanggaran/${id}`)
}
export function updateDugaanPelanggaran($data) {
  return update('/dugaan-pelanggaran', $data)
}
// =====================================
//===============dugaan pelanggaran==================
export function tambahJenisPerkara($data) {
  return post('/jenis-perkara', $data)
}
export function deleteJenisPerkara($data) {
  return post('/jenis-perkara/delete', $data)
}
export function getJenisPerkaraById(id) {
  return get(`/jenis-perkara/${id}`)
}
export function updateJenisPerkara($data) {
  return update('/jenis-perkara', $data)
}
// =====================================
//===============dugaan pelanggaran==================
export function tambahJenisPenyampaian($data) {
  return post('/jenis-penyampaian', $data)
}
export function deleteJenisPenyampaian($data) {
  return post('/jenis-penyampaian/delete', $data)
}
export function getMasterLaporan() {
  return get('/master/laporan')
}
export function getJenisPenyampaian() {
  return get('/jenis-penyampaian')
}
export function getJenisPenyampaianById(id) {
  return get(`/jenis-penyampaian/${id}`)
}
export function updateJenisPenyampaian($data) {
  return update('/jenis-penyampaian', $data)
}
// =====================================
//===============Tim==================
export function tambahTim($data) {
  return post('/tim', $data)
}
export function deleteTim($data) {
  return post('/tim/delete', $data)
}
export function getTimById(id) {
  return get(`/tim/${id}`)
}
export function updateTim($data) {
  return update('/tim', $data)
}
// =====================================
//===============Tim==================
export function tambahTimMajelis($data) {
  return post('/tim-majelis', $data)
}
export function deleteTimMajelis($data) {
  return post('/tim-majelis/delete', $data)
}
export function getTimMajelisById(id) {
  return get(`/tim-majelis/${id}`)
}
export function updateTimMajelis($data) {
  return update('/tim-majelis', $data)
}
// =====================================
//===============Tim==================
export function tambahTimAhli($data) {
  return post('/tim-ahli', $data)
}
export function deleteTimAhli($data) {
  return post('/tim-ahli/delete', $data)
}
export function getTimAhliById(id) {
  return get(`/tim-ahli/${id}`)
}
export function updateTimAhli($data) {
  return update('/tim-ahli', $data)
}
// =====================================
//===============Tim==================
export function tambahTimPanel($data) {
  return post('/tim-panel', $data)
}
export function deleteTimPanel($data) {
  return post('/tim-panel/delete', $data)
}
export function getTimPanelById(id) {
  return get(`/tim-panel/${id}`)
}
export function updateTimPanel($data) {
  return update('/tim-panel', $data)
}
// =====================================
//===============dugaan pelanggaran==================
export function tambahJenisPekerjaan($data) {
  return post('/jenis-pekerjaan', $data)
}
export function deleteJenisPekerjaan($data) {
  return post('/jenis-pekerjaan/delete', $data)
}
export function getJenisPekerjaanById(id) {
  return get(`/jenis-pekerjaan/${id}`)
}
export function updateJenisPekerjaan($data) {
  return update('/jenis-pekerjaan', $data)
}
// =====================================
//===============dugaan pelanggaran==================
export function tambahKantorPenghubung($data) {
  return post('/kantor-penghubung', $data)
}
export function deleteKantorPenghubung($data) {
  return post('/kantor-penghubung/delete', $data)
}
export function getKantorPenghubungById(id) {
  return get(`/kantor-penghubung/${id}`)
}
export function updateKantorPenghubung($data) {
  return update('/kantor-penghubung', $data)
}
// =====================================
//===============dugaan pelanggaran==================
export function tambahLingkunganPeradilan($data) {
  return post('/lingkungan-peradilan', $data)
}
export function deleteLingkunganPeradilan($data) {
  return post('/lingkungan-peradilan/delete', $data)
}
export function getLingkunganPeradilanById(id) {
  return get(`/lingkungan-peradilan/${id}`)
}
export function updateLingkunganPeradilan($data) {
  return update('/lingkungan-peradilan', $data)
}
// =====================================
//===============dugaan pelanggaran==================
export function tambahAgama($data) {
  return post('/agama', $data)
}
export function deleteAgama($data) {
  return post('/agama/delete', $data)
}
export function getAgamaById(id) {
  return get(`/agama/${id}`)
}
export function updateAgama($data) {
  return update('/agama', $data)
}
// =====================================

//===============Status Investigasi Advokasi==================
export function tambahStatusInvestigasiAdvokasi($data) {
  return post('/status-investigasi-advokasi', $data)
}
export function deleteStatusInvestigasiAdvokasi($data) {
  return post('/status-investigasi-advokasi/delete', $data)
}
export function getStatusInvestigasiAdvokasiById(id) {
  return get(`/status-investigasi-advokasi/${id}`)
}
export function updateStatusInvestigasiAdvokasi($data) {
  return update('/status-investigasi-advokasi', $data)
}
// =====================================
//===============Template==================
export function tambahTemplate($data) {
  return post('/template', $data)
}
export function deleteTemplate($data) {
  return post('/template/delete', $data)
}
export function getTemplateById(id) {
  return get(`/template/${id}`)
}
export function updateTemplate($data) {
  return update('/template', $data)
}
//===============Status Investigasi Advokasi==================
//===============dugaan pelanggaran==================
export function tambahStatusBerkas($data) {
  return post('/status-berkas', $data)
}
export function deleteStatusBerkas($data) {
  return post('/status-berkas/delete', $data)
}
export function getStatusBerkasById(id) {
  return get(`/status-berkas/${id}`)
}
export function updateStatusBerkas($data) {
  return update('/status-berkas', $data)
}
// =====================================
//===============dugaan pelanggaran==================
export function tambahStatusPemantauan($data) {
  return post('/status-pemantauan', $data)
}
export function deleteStatusPemantauan($data) {
  return post('/status-pemantauan/delete', $data)
}
export function getStatusPemantauanById(id) {
  return get(`/status-pemantauan/${id}`)
}
export function updateStatusPemantauan($data) {
  return update('/status-pemantauan', $data)
}
// =====================================
//===============dugaan pelanggaran==================
export function tambahJenisDokumen($data) {
  return post('/jenis-dokumen', $data)
}
export function deleteJenisDokumen($data) {
  return post('/jenis-dokumen/delete', $data)
}
export function getJenisDokumenById(id) {
  return get(`/jenis-dokumen/${id}`)
}
export function updateJenisDokumen($data) {
  return update('/jenis-dokumen', $data)
}
// =====================================
//===============Jenis Dokumen MKH==================
export function tambahJenisDokumenMkh($data) {
  return post('/jenis-dokumen-mkh', $data)
}
export function deleteJenisDokumenMkh($data) {
  return post('/jenis-dokumen-mkh/delete', $data)
}
export function getJenisDokumenMkhById(id) {
  return get(`/jenis-dokumen-mkh/${id}`)
}
export function updateJenisDokumenMkh($data) {
  return update('/jenis-dokumen-mkh', $data)
}
// =====================================

//===============dugaan pelanggaran==================
export function tambahJenisKelamin($data) {
  return post('/jenis-kelamin', $data)
}
export function deleteJenisKelamin($data) {
  return post('/jenis-kelamin/delete', $data)
}
export function getJenisKelaminById(id) {
  return get(`/jenis-kelamin/${id}`)
}
export function updateJenisKelamin($data) {
  return update('/jenis-kelamin', $data)
}
// =====================================
//===============dugaan pelanggaran==================
export function tambahJenisInstrumen($data) {
  return post('/jenis-instrumen', $data)
}
export function deleteJenisInstrumen($data) {
  return post('/jenis-instrumen/delete', $data)
}
export function getJenisInstrumenById(id) {
  return get(`/jenis-instrumen/${id}`)
}
export function updateJenisInstrumen($data) {
  return update('/jenis-instrumen', $data)
}
// =====================================
//===============dugaan pelanggaran==================
export function tambahJenisKelengkapan($data) {
  return post('/jenis-kelengkapan', $data)
}
export function deleteJenisKelengkapan($data) {
  return post('/jenis-kelengkapan/delete', $data)
}
export function getJenisKelengkapanById(id) {
  return get(`/jenis-kelengkapan/${id}`)
}
export function updateJenisKelengkapan($data) {
  return update('/jenis-kelengkapan', $data)
}
// =====================================
//===============dugaan pelanggaran==================
export function tambahJenisLaporan($data) {
  return post('/jenis-laporan', $data)
}
export function deleteJenisLaporan($data) {
  return post('/jenis-laporan/delete', $data)
}
export function getJenisLaporanById(id) {
  return get(`/jenis-laporan/${id}`)
}
export function updateJenisLaporan($data) {
  return update('/jenis-laporan', $data)
}
// =====================================
//===============dugaan pelanggaran==================
export function tambahJenisPengadilan($data) {
  return post('/jenis-pengadilan', $data)
}
export function deleteJenisPengadilan($data) {
  return post('/jenis-pengadilan/delete', $data)
}
export function getJenisPengadilanById(id) {
  return get(`/jenis-pengadilan/${id}`)
}
export function updateJenisPengadilan($data) {
  return update('/jenis-pengadilan', $data)
}
// =====================================
//===============dugaan pelanggaran==================
export function tambahJenisTindakanPemantauan($data) {
  return post('/jenis-tindakan-pemantauan', $data)
}
export function deleteJenisTindakanPemantauan($data) {
  return post('/jenis-tindakan-pemantauan/delete', $data)
}
export function getJenisTindakanPemantauanById(id) {
  return get(`/jenis-tindakan-pemantauan/${id}`)
}
export function updateJenisTindakanPemantauan($data) {
  return update('/jenis-tindakan-pemantauan', $data)
}
// =====================================
//===============dugaan pelanggaran==================
export function tambahDaftarTim($data) {
  return post('/daftar-tim', $data)
}
export function deleteDaftarTim($data) {
  return post('/daftar-tim/delete', $data)
}
export function getDaftarTimById(id) {
  return get(`/daftar-tim/${id}`)
}
export function updateDaftarTim($data) {
  return update('/daftar-tim', $data)
}
// =====================================
//===============dugaan pelanggaran==================
export function tambahBadanPeradilan($data) {
  return post('/badan-peradilan', $data)
}
export function deleteBadanPeradilan($data) {
  return post('/badan-peradilan/delete', $data)
}
export function getBadanPeradilanById(id) {
  return get(`/badan-peradilan/${id}`)
}
export function updateBadanPeradilan($data) {
  return update('/badan-peradilan', $data)
}
// =====================================

//===============Data Dashboard==================
export function getDataDashboard($data) {
  return get('/data-dashboard', $data)
}
// =====================================
//===============Kelengkapan Data==================
export function tambahKelengkapanData($data) {
  return post('/kelengkapan-data', $data)
}
export function deleteKelengkapanData($data) {
  return post('/kelengkapan-data/delete', $data)
}
export function getKelengkapanDataById(id) {
  return get(`/kelengkapan-data/${id}`)
}
export function updateKelengkapanData($data) {
  return update('/kelengkapan-data', $data)
}
export function uploadFileKelengkapanData(file, onUploadProgress) {
  return upload(`/kelengkapan-data/uploadfile`, file, onUploadProgress)
}
// export function downloadDokumenKelengkapanData($filename) {
//   return download(`kelengkapan-data/download/${$filename}`)
// }
export function downloadDokumenKelengkapanData(filename) {
  if (filename.includes("http")) {
    // window.location.href = filename;
    window.open(filename, "_blank");
  } else {
    download(`kelengkapan-data/download/${filename}`);
  }
}

// =====================================
//===============Kuasa Pelapor==================
export function tambahKuasaPelapor($data) {
  return post('/kuasa-pelapor', $data)
}
export function deleteKuasaPelapor($data) {
  return post('/kuasa-pelapor/delete', $data)
}
export function getKuasaPelaporById(id) {
  return get(`/kuasa-pelapor/${id}`)
}
export function updateKuasaPelapor($data) {
  return update('/kuasa-pelapor', $data)
}
export function selectKuasaPelapor($data) {
  return update('/select-kuasa-pelapor', $data)
}
export function unselectKuasaPelapor($data) {
  return update('/unselect-kuasa-pelapor', $data)
}
// =====================================
//===============Terlapor==================
export function tambahTerlapor($data) {
  return post('/terlapor', $data)
}
export function deleteTerlapor($data) {
  return post('/terlapor/delete', $data)
}
export function getTerlaporById(id) {
  return get(`/terlapor/${id}`)
}
export function getTerlapor(data) {
  return get(`/terlapor`, data)
}
export function updateTerlapor($data) {
  return update('/terlapor', $data)
}
export function selectTerlapor($data) {
  return update('/select-terlapor', $data)
}
export function unselectTerlapor($data) {
  return update('/unselect-terlapor', $data)
}
// =====================================
//===============Pelapor==================
export function tambahPelapor($data) {
  return post('/pelapor', $data)
}
export function deletePelapor($data) {
  return post('/pelapor/delete', $data)
}
export function getPelaporById(id) {
  return get(`/pelapor/${id}`)
}
export function updatePelapor($data) {
  return update('/pelapor', $data)
}

export function unselectPelapor($data) {
  return update('/unselect-pelapor', $data)
}

export function selectPelapor($data) {
  return update('/select-pelapor', $data)
}

export function deletePenerimaan($data) {
  return post('/penerimaan/delete', $data)
}

// =====================================
//===============Verifikasi==================
// export function tambahTerlapor($data) {
//   return post('/terlapor', $data)
// }
// export function deleteTerlapor($data) {
//   return post('/terlapor/delete', $data)
// }
export function getVerifikasiByLaporanId(id) {
  return get(`/verifikasi/${id}`)
}
export function updateVerifikasi($data) {
  return update('/verifikasi', $data)
}
export function updatePenerimaan($data) {
  return update('/penerimaan', $data)
}

// export function updateTerlapor($data) {
//   return update('/terlapor', $data)
// }

export function tambahKasusPosisi($data) {
  return post('/kasus-posisi', $data)
}
export function deleteKasusPosisi($data) {
  return post('/kasus-posisi/delete', $data)
}
export function getKasusPosisiById(id) {
  return get(`/kasus-posisi/${id}`)
}
export function updateKasusPosisi($data) {
  return update('/kasus-posisi', $data)
}

export function tambahDasarPemeriksaan($data) {
  return post('/dasar-pemeriksaan', $data)
}
export function deleteDasarPemeriksaan($data) {
  return post('/dasar-pemeriksaan/delete', $data)
}
export function getDasarPemeriksaanById(id) {
  return get(`/dasar-pemeriksaan/${id}`)
}
export function updateDasarPemeriksaan($data) {
  return update('/dasar-pemeriksaan', $data)
}

export function tambahTujuanPemeriksaan($data) {
  return post('/tujuan-pemeriksaan', $data)
}
export function deleteTujuanPemeriksaan($data) {
  return post('/tujuan-pemeriksaan/delete', $data)
}
export function getTujuanPemeriksaanById(id) {
  return get(`/tujuan-pemeriksaan/${id}`)
}
export function updateTujuanPemeriksaan($data) {
  return update('/tujuan-pemeriksaan', $data)
}

export function getDataBuatJadwalSidangPanel($data) {
  return post('/get-data-buat-jadwal-sidang-panel', $data)
}

export function getDataBuatJadwalSidangPleno($data) {
  return post('/get-data-buat-jadwal-sidang-pleno', $data)
}

export function tambahPelaksanaanPemeriksaan($data) {
  return post('/pelaksanaan-pemeriksaan', $data)
}
export function deletePelaksanaanPemeriksaan($data) {
  return post('/pelaksanaan-pemeriksaan/delete', $data)
}
export function getPelaksanaanPemeriksaanById(id) {
  return get(`/pelaksanaan-pemeriksaan/${id}`)
}
export function updatePelaksanaanPemeriksaan($data) {
  return update('/pelaksanaan-pemeriksaan', $data)
}

export function tambahHasilPemeriksaan($data) {
  return post('/hasil-pemeriksaan', $data)
}
export function deleteHasilPemeriksaan($data) {
  return post('/hasil-pemeriksaan/delete', $data)
}
export function getHasilPemeriksaanById(id) {
  return get(`/hasil-pemeriksaan/${id}`)
}
export function updateHasilPemeriksaan($data) {
  return update('/hasil-pemeriksaan', $data)
}

export function tambahPertanyaanJawaban($data) {
  return post('/pertanyaan-jawaban', $data)
}
export function deletePertanyaanJawaban($data) {
  return post('/pertanyaan-jawaban/delete', $data)
}
export function getPertanyaanJawabanById(id) {
  return get(`/pertanyaan-jawaban/${id}`)
}
export function updatePertanyaanJawaban($data) {
  return update('/pertanyaan-jawaban', $data)
}

export function tambahPokokLaporan($data) {
  return post('/pokok-laporan', $data)
}
export function deletePokokLaporan($data) {
  return post('/pokok-laporan/delete', $data)
}
export function getPokokLaporanById(id) {
  return get(`/pokok-laporan/${id}`)
}
export function updatePokokLaporan($data) {
  return update('/pokok-laporan', $data)
}

export function tambahAnalisaPersyaratanLaporan($data) {
  return post('/analisa-persyaratan-laporan', $data)
}
export function deleteAnalisaPersyaratanLaporan($data) {
  return post('/analisa-persyaratan-laporan/delete', $data)
}
export function getAnalisaPersyaratanLaporanById(id) {
  return get(`/analisa-persyaratan-laporan/${id}`)
}
export function updateAnalisaPersyaratanLaporan($data) {
  return update('/analisa-persyaratan-laporan', $data)
}

export function tambahSimpulan($data) {
  return post('/simpulan', $data)
}
export function deleteSimpulan($data) {
  return post('/simpulan/delete', $data)
}
export function getSimpulanById(id) {
  return get(`/simpulan/${id}`)
}
export function updateSimpulan($data) {
  return update('/simpulan', $data)
}

export function tambahSaran($data) {
  return post('/saran', $data)
}
export function deleteSaran($data) {
  return post('/saran/delete', $data)
}
export function getSaranById(id) {
  return get(`/saran/${id}`)
}
export function updateSaran($data) {
  return update('/saran', $data)
}

export function tambahCatatanTenagaAhli($data) {
  return post('/catatan-tenaga-ahli', $data)
}
export function deleteCatatanTenagaAhli($data) {
  return post('/catatan-tenaga-ahli/delete', $data)
}
export function getCatatanTenagaAhliById(id) {
  return get(`/catatan-tenaga-ahli/${id}`)
}
export function updateCatatanTenagaAhli($data) {
  return update('/catatan-tenaga-ahli', $data)
}

export function tambahKesimpulanTim($data) {
  return post('/kesimpulan-tim', $data)
}
export function deleteKesimpulanTim($data) {
  return post('/kesimpulan-tim/delete', $data)
}
export function getKesimpulanTimById(id) {
  return get(`/kesimpulan-tim/${id}`)
}
export function updateKesimpulanTim($data) {
  return update('/kesimpulan-tim', $data)
}

export function tambahTenagaAhli($data) {
  return post('/tenaga-ahli', $data)
}
export function deleteTenagaAhli($data) {
  return post('/tenaga-ahli/delete', $data)
}
export function getTenagaAhliById(id) {
  return get(`/tenaga-ahli/${id}`)
}
export function updateTenagaAhli($data) {
  return update('/tenaga-ahli', $data)
}
// =====================================
//===============Distribusi Berkas==================
export function tambahDistribusiBerkas($data) {
  return post('/laporan/distribusi-berkas', $data)
}
export function deleteDistribusiBerkas($data) {
  return post('/laporan/distribusi-berkas/delete', $data)
}
export function getDistribusiBerkasById(id) {
  return get(`/laporan/distribusi-berkas/${id}`)
}
export function updateDistribusiBerkas($data) {
  return update('/laporan/distribusi-berkas', $data)
}
// =====================================
//===============Jenis Surat==================
export function tambahJenisSurat($data) {
  return post('/jenis-surat', $data)
}
export function deleteJenisSurat($data) {
  return post('/jenis-surat/delete', $data)
}
export function getJenisSuratById(id) {
  return get(`/jenis-surat/${id}`)
}
export function updateJenisSurat($data) {
  return update('/jenis-surat', $data)
}
// =====================================
//===============Surat==================
export function tambahSurat($data) {
  return post('/surat', $data)
}
export function deleteSurat($data) {
  return post('/surat/delete', $data)
}
export function getSuratById(id) {
  return get(`/surat/${id}`)
}
export function updateSurat($data) {
  return update('/surat', $data)
}
export function uploadFileSurat(file, onUploadProgress) {
  return upload(`/surat/uploadfile`, file, onUploadProgress)
}
export function downloadSurat($filename) {
  return download(`surat/download/${$filename}`)
}
// =====================================
export function tambahDokumen($data) {
  return post('/dokumen', $data)
}
export function deleteDokumen($data) {
  return post('/dokumen/delete', $data)
}
export function getDokumenById(id) {
  return get(`/dokumen/${id}`)
}
export function updateDokumen($data) {
  return update('/dokumen', $data)
}
export function uploadFileDokumen(file, onUploadProgress) {
  return upload(`/dokumen/uploadfile`, file, onUploadProgress)
}
export function downloadDokumen($filename) {
  return download(`dokumen/download/${$filename}`)
}
// =====================================
// =====================================
export function tambahDokumenMKH($data) {
  return post('/dokumen-mkh', $data)
}
export function deleteDokumenMKH($data) {
  return post('/dokumen-mkh/delete', $data)
}
export function getDokumenMKHById(id) {
  return get(`/dokumen-mkh/${id}`)
}
export function updateDokumenMKH($data) {
  return update('/dokumen-mkh', $data)
}
export function uploadFileDokumenMKH(file, onUploadProgress) {
  return upload(`/dokumen-mkh/uploadfile`, file, onUploadProgress)
}
export function downloadDokumenMKH($filename) {
  return download(`dokumen-mkh/download/${$filename}`)
}
// =====================================
// =====================================
export function tambahBeritaAcara($data) {
  return post('/berita-acara', $data)
}
export function deleteBeritaAcara($data) {
  return post('/berita-acara/delete', $data)
}
export function getBeritaAcaraById(id) {
  return get(`/berita-acara/${id}`)
}
export function updateBeritaAcara($data) {
  return update('/berita-acara', $data)
}
export function uploadFileBeritaAcara(file, onUploadProgress) {
  return upload(`/berita-acara/uploadfile`, file, onUploadProgress)
}
export function downloadBeritaAcara($filename) {
  return download(`berita-acara/download/${$filename}`)
}
// =====================================
export function tambahDataPendukung($data) {
  return post('/data-pendukung', $data)
}
export function deleteDataPendukung($data) {
  return post('/data-pendukung/delete', $data)
}
export function uploadFileDataPendukung(file, onUploadProgress) {
  return upload(`/data-pendukung/uploadfile`, file, onUploadProgress)
}
export function downloadDataPendukung($filename) {
  return download(`data-pendukung/download/${$filename}`)
}


export function getDataPendukungById(id) {
  return get(`/data-pendukung/${id}`)
}
export function updateDataPendukung($data) {
  return update('/data-pendukung', $data)
}
// =====================================
export function tambahAnalisis($data) {
  return post('/analisis', $data)
}
export function deleteAnalisis($data) {
  return post('/analisis/delete', $data)
}
export function getAnalisisById(id) {
  return get(`/analisis/${id}`)
}
export function updateAnalisis($data) {
  return update('/analisis', $data)
}
export function uploadFileAnalisis(file, onUploadProgress) {
  return upload(`/analisis/uploadfile`, file, onUploadProgress)
}
export function downloadAnalisis($filename) {
  return download(`analisis/download/${$filename}`)
}
//=======================================
export function tambahKesimpulan($data) {
  return post('/kesimpulan', $data)
}
export function deleteKesimpulan($data) {
  return post('/kesimpulan/delete', $data)
}
export function getKesimpulanById(id) {
  return get(`/kesimpulan/${id}`)
}
export function updateKesimpulan($data) {
  return update('/kesimpulan', $data)
}
//=======================================
export function tambahSaranAnalisis($data) {
  return post('/saran-analisis', $data)
}
export function deleteSaranAnalisis($data) {
  return post('/saran-analisis/delete', $data)
}
export function getSaranAnalisisById(id) {
  return get(`/saran-analisis/${id}`)
}
export function updateSaranAnalisis($data) {
  return update('/saran-analisis', $data)
}

//================minutasi=================
export function tambahDataMinutasi($data) {
  return post('/minutasi', $data)
}
export function deleteMinutasi($data) {
  return post('/minutasi/delete', $data)
}
export function getMinutasiById(id) {
  return get(`/minutasi/${id}`)
}
export function updateMinutasi($data) {
  return update('/minutasi', $data)
}
//======================================

export function toVerifPenerimaan($id) {
  return post('/penerimaan/toVerif', $id)
}

export function toArsip($id) {
  return post('/penerimaan/toArsip', $id)
}
// =====================================
export function tambahTandaTangan($data) {
  return post('/tanda-tangan', $data)
}
export function deleteTandaTangan($data) {
  return post('/tanda-tangan/delete', $data)
}
export function uploadFileTandaTangan(file, onUploadProgress) {
  return upload(`/tanda-tangan/uploadfile`, file, onUploadProgress)
}
export function downloadTandaTangan($filename) {
  return download(`tanda-tangan/download/${$filename}`)
}


export function getTandaTanganById(id) {
  return get(`/tanda-tangan/${id}`)
}
export function updateTandaTangan($data) {
  return update('/tanda-tangan', $data)
}
//Post putusan
export function postPutusan($data) {
  return post('/putusan/post', $data)
}

export function exportLaporanPeriodik(tahun) {
  return download(`laporan-periodik/exportExcel/${tahun}`)
}

export function exportLaporanBulanan(tanggal_awal,tanggal_akhir) {
  return download(`download-laporan/${tanggal_awal}/${tanggal_akhir}`)
}

export function updateDataLanjutkanSidangPleno($data) {
  return post(`/panel-jadwal/lanjutkan-sidang-pleno`, $data)
}

export function downloadPerihal(filename) {
  return download(`/perihal/download/${filename}`)
}
